const { isEmpty, } = require('lodash');

const { entries } = Object;

const fields = () => {
  return {
    note: {
      label: 'メモ',
      type: 'text',
    },
  };
};

exports.fields = fields;
